body {
    background: url('/img/error.jpg') no-repeat center center;
    background-size: cover;
}
#container .whiteContent {
    background: transparent;
}
#app {
    h1, h3 {
        color: white;
        font-weight: bold;
        font-family: Arial, serif;
    }
    h1 {
        font-size: 9rem;
    }
    p {
        color: white;
    }
    .well {
        background: white;
        color: black;
        h1, h2, h3, p {
            color: inherit;
        }
    }
}
